































































































































































































































































































import axios from 'axios';
import { getComponent, getConfigEnv } from '@/utils/helpers';

import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    AuthLayout: () => getComponent('auth/AuthLayout'),
  },
})
export default class SignUp extends Vue {
  CODE_LENGTH = 6;

  email = '';

  phoneNumber = '';

  firstName = '';

  lastName = '';

  actionShown = 'signup';

  code = ['', '', '', '', '', ''];

  errorMessage = '';

  emailErrorMessage = '';
  firstNameErrorMessage = '';
  lastNameErrorMessage = '';
  roleErrorMessage = '';
  phoneNumberErrorMessage = '';


  loading = false;

  dataLoading = false

  roleSelection = ''

  get verifyBtnDisabled() {
    return !this.code.every((i) => i !== '');
  }

  changeInputFocus(idx) {
    if (this.code[idx] === '') {
      const index = idx === 0 ? 0 : idx - 1;

      this.$refs[`codeInput-${index}`][0].focus();

      return;
    }

    if (this.code[idx] && idx === this.CODE_LENGTH - 1) {
      this.$refs[`codeInput-${this.CODE_LENGTH - 1}`][0].blur();

      return;
    }

    this.$nextTick(() => {
      const index = idx === this.CODE_LENGTH - 1 ? this.CODE_LENGTH - 1 : idx + 1;

      this.$refs[`codeInput-${index}`][0].focus();
    });
  }

  async signup() {
    this.errorMessage = '';

    this.loading = true;
    this.dataLoading = true;

	if (this.email == '' || this.firstName == '' || this.lastName == '' || this.roleSelection == '' || this.email == '' || this.phoneNumber == '') {
		if (this.email == '') {
			this.emailErrorMessage = "Email must not be blank"
		} else {
			this.emailErrorMessage = ''
		}
		if (this.firstName == '') {
			this.firstNameErrorMessage = "First name must not be blank"
		} else {
			this.firstNameErrorMessage = ''
		}
		if (this.lastName == '') {
			this.lastNameErrorMessage = "Last name must not be blank"
		} else {
			this.lastNameErrorMessage = ''
		}
		if (this.roleSelection == '') {
			this.roleErrorMessage = "Role must not be blank"
		} else {
			this.roleErrorMessage = ''
		}
		if (this.email == '') {
			this.emailErrorMessage = "Email must not be blank"
		} else {
			this.emailErrorMessage = ''
		}
		if (this.phoneNumber == '') {
			this.phoneNumberErrorMessage = "Phone number must not be blank"
		} else {
			this.phoneNumberErrorMessage = ''
		}
		return
	}



    try {
      const {
        data: {
          session,
          tokens,
        },
      } = await axios.post(`${getConfigEnv('AUTH_ENDPOINT_URL')}/signup`, {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: `+1${this.phoneNumber}`,
        roles: [this.roleSelection],
        username: this.firstName.toLowerCase() + "_" + this.lastName.toLowerCase(),
      });

      if (session) {
        localStorage.setItem('session', session);

        localStorage.setItem('email', this.email.toLowerCase());
        localStorage.setItem('firstName', this.firstName);
        localStorage.setItem('lastName', this.lastName);

        this.loading = false;
        this.dataLoading = false;

        this.actionShown = 'verify';

        return;
      }

      if (tokens) {
        localStorage.removeItem('IdToken');

        localStorage.removeItem('RefreshToken');

        Object.keys(tokens).map((token) => localStorage.setItem(token, tokens[token]));

        this.loading = false;
        this.dataLoading = false;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Logging ERROR: ${e}`);
      if (e.message === 'User does not exist.') {
        e.message = 'Please check your email.';
      }

      this.errorMessage = e.message;

      this.loading = false;
	  this.dataLoading = false;
    }
  }

  async verify() {
    this.loading = true;

    this.errorMessage = '';

    try {
      const {
        data: { tokens },
      } = await axios.post(`${getConfigEnv('AUTH_ENDPOINT_URL')}/auth`, {
        type: 'respond',

        session: localStorage.getItem('session'),

        email: localStorage.getItem('email'),

        code: this.code.join(''),
      });

      if (tokens) {
        localStorage.removeItem('IdToken');

        localStorage.removeItem('RefreshToken');

        Object.keys(tokens).map((token) => localStorage.setItem(token === 'IdToken' ? 'AccessToken' : token, tokens[token]));

        this.loading = false;
      }

      this.$router.push({
        name: 'Tasqs',
      });

      this.loading = false;
    } catch (e) {
      e.message = 'Let’s try that again — check your code and make sure you enter the correct numbers.';

      this.errorMessage = e.message;

      this.loading = false;
    }
  }
}
